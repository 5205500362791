import { createSlice } from '@reduxjs/toolkit';


const normSlice = createSlice({
  name: 'norm',
  initialState: {
    norms: {},
  },
  reducers: {
    setState (state, action) {
      state.norms = action.payload;
    },
  }
});


export const normActions = normSlice.actions;

export default normSlice.reducer;
