import React, { useState, useEffect, useCallback } from "react"
import Header from "components/Headers/Header"
import { useParams } from "react-router-dom"
import { useSelector } from "react-redux"
import { useAuth } from "store/auth-context"
import { DATA_URL } from "urls"
import { refreshFecth } from "store/refresh-request"
import { Pagination } from '@material-ui/lab'
import { Link } from 'react-router-dom';
import moment from 'moment';
import {
  Card,
  CardBody,
  CardHeader,
  Breadcrumb,
  BreadcrumbItem,
  CardFooter,
  Row,
  Col,
  Button
} from 'reactstrap'
import DataTable from "components/History/Table"
import DatePicker from "components/DatePicker"


const useGetData = ({ imei, start, end, page }) => {
  const [count, setCount] = useState(0)
  const [results, setResults] = useState([])
  const [clickable, setClickable] = useState(true)
  const auth = useAuth()
  useEffect(() => {
    if (!imei) return
    let url = new URL(`${DATA_URL + imei}/data/`)

    if (page) url.searchParams.set('page', page)
    if (start) url.searchParams.set('start', start)
    if (end) url.searchParams.set('end', end)

    const fetchData = async () => {
      setClickable(false)
      const response = await fetch(url, {
        headers: {
          'Authorization': 'Bearer ' + auth.accessToken,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        if (response.status === 401) {
          refreshFecth(auth)
          return
        } else {
          throw new Error(`Network response was not OK . Status = ${response.status}`)
        }
      }

      const data = await response.json();

      setCount(data.count)
      setResults(data.results)
      setClickable(true)
    }

    fetchData();
  }, [auth, imei, start, end, page])

  return {
    count, results, clickable
  }
}

const History = () => {
  const { imei } = useParams();

  const devices = useSelector(state => state.device.devices);
  const device = devices.find(device => device.imei === imei);

  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState({
    start: null,
    end: null,
  })

  const { count, results, clickable } = useGetData({
    imei, page, ...search
  });

  const startChange = useCallback((value) => {
    if (value instanceof moment) {
      setStart(value.format('YYYY-MM-DD HH:mm'));
    } else if (!value) {
      setStart(null);
    }
  }, []);

  const endChange = useCallback((value) => {
    if (value instanceof moment) {
      setEnd(value.format('YYYY-MM-DD HH:mm'));
    } else if (!value) {
      setEnd(null);
    }
  }, []);

  return (
    <>
      <Header />
      <Card>
        <CardHeader>
          <Breadcrumb className="breadcrumb-links breadcrumb-dark bg-transparent">
            <BreadcrumbItem>
              <Link to="/devices" className="text-default">
                <i className="fa fa-hdd mr-3"></i>
                Cihazlar
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem className="breadcrumb-links breadcrumb-dark bg-transparent">
              <Link to={`/devices/${device?.imei}`} className="text-default">
                {device?.name} - {device?.imei}
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem className="active">
              Tarixçə
            </BreadcrumbItem>
          </Breadcrumb>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md="7" className="mb-2">
              <DatePicker
                start={start}
                end={end}
                onStartChange={startChange}
                onEndChange={endChange}
              />
            </Col>
            <Col md="3" className="mb-2">
              <Button
                color="info"
                onClick={e => setSearch({start, end})}
                disabled={!clickable}
              >
                Axtar
              </Button>
            </Col>
          </Row>
          <DataTable results={results} count={count} />
        </CardBody>
        {(results) ?
          <CardFooter className="py-4">
            <nav aria-label="...">
              <Pagination
                count={Math.ceil(count/100)}
                page={page}
                onChange={(_, page) => {
                  setPage(page)
                  window.scrollTo({
                    top: 380,
                    left: 0,
                  })
                }}
                className="pagination justify-content-end mb-0"
                color="primary"
              />
            </nav>
          </CardFooter> : null}
      </Card>
    </>
  );
}


export default History;
