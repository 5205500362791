/*eslint-disable*/
import { useState, useCallback } from "react";
import { NavLink as NavLinkRRD, Link, useHistory, useLocation } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";
// import { useTable } from "store/table-context";
import { useSelector, useDispatch } from 'react-redux';
import { tableActions, TEMPERATURE, NH, CO, VOICE } from "store/table-slice";
// reactstrap components
import {
  // Button,
  // Card,
  // CardHeader,
  // CardBody,
  // CardTitle,
  Collapse,
  // DropdownMenu,
  // DropdownItem,
  // UncontrolledDropdown,
  // DropdownToggle,
  // FormGroup,
  // Form,
  // Input,
  // InputGroupAddon,
  // InputGroupText,
  // InputGroup,
  // Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  // Progress,
  // Table,
  Container,
  Row,
  Col,
  Badge,
} from "reactstrap";

// var ps;

const Sidebar = (props) => {
  const [collapseOpen, setCollapseOpen] = useState();
  const [deviceCollapse, setDeviceCollapse] = useState(false);
  const history = useHistory();
  const location = useLocation();
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  // toggles collapse between opened and closed (true/false)
  const toggleCollapse = useCallback(() => {
    setCollapseOpen((data) => !data);
  }, []);
  // closes the collapse
  const closeCollapse = useCallback(() => {
    setCollapseOpen(false);
  }, []);
  // const toggleDeviceCollapse = () => {}

  const checkTableActive = useCallback((self, active) => {
    return (self === active) ? "active" : "";
  }, []);

  let activeTable = useSelector(state => state.table.active);
  const dispatch = useDispatch();
  if(location.pathname.indexOf("devices") === -1) {
    activeTable = "null";
  }
  const { logo, isOpen, setIsOpen } = props;
  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank",
    };
  }

  const setTable = useCallback((action, pathname, history) => {
    dispatch(action);
    if(pathname.indexOf("devices") === -1) {
      history.replace('/devices');
    }
  }, [dispatch]);

  let toggleIcon = (isOpen) ? 'fas fa-arrow-left fa-1x' : 'fas fa-arrow-right fa-1x';

  //let deviceHref = props.location.pathname.indexOf("devices") > -1 ? props.location.pathname : "/devices";

  return (
    <Navbar
      className={isOpen ?
        "navbar-vertical fixed-left navbar-light bg-white extra-sidebar-style"
        : "navbar-vertical fixed-left navbar-light bg-white custom-width extra-sidebar-style not-is-open"}
      // className="navbar-vertical fixed-left navbar-light bg-white"
      expand="md"
      id="sidenav-main"
    >
      <Badge
        className="sidebar-toogle-btn"
        onClick={(e) => {
          e.preventDefault();
          setIsOpen(!isOpen);
        }}
      >
        <i className={toggleIcon}></i>
      </Badge>
      <Container fluid>
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleCollapse}
        >
          <span className="navbar-toggler-icon" />
        </button>
        {logo ? (
          <NavbarBrand className="pt-0" {...navbarBrandProps}>
            <img
              alt={logo.imgAlt}
              className="navbar-brand-img"
              src={logo.imgSrc}
            />
          </NavbarBrand>
        ) :
          null}
        {/* User */}
        {/* <Nav className="align-items-center d-md-none">
          <UncontrolledDropdown nav>
            <DropdownToggle nav>
              <Media className="align-items-center">
                <span className="avatar avatar-sm rounded-circle">
                  <img
                    alt="..."
                    src={
                      require("../../assets/img/theme/team-1-800x800.jpg")
                        .default
                    }
                  />
                </span>
              </Media>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow" right>
              <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
                <i className="ni ni-user-run" />
                <span>Logout</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav> */}
        {/* Collapse */}
        <Collapse navbar isOpen={collapseOpen}>
          {/* Collapse header */}
          <div className="navbar-collapse-header d-md-none">
            <Row>
              {logo ? (
                <Col className="collapse-brand" xs="6">
                  {logo.innerLink ? (
                    <Link to={logo.innerLink}>
                      <img alt={logo.imgAlt} src={logo.imgSrc} />
                    </Link>
                  ) : (
                    <a href={logo.outterLink}>
                      <img alt={logo.imgAlt} src={logo.imgSrc} />
                    </a>
                  )}
                </Col>
              ) : null}
              <Col className="collapse-close" xs="6">
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={toggleCollapse}
                >
                  <span />
                  <span />
                </button>
              </Col>
            </Row>
          </div>
          <Nav navbar>
            <NavItem>
              <NavLink
                to='/map'
                tag={NavLinkRRD}
                onClick={e => {closeCollapse(); setDeviceCollapse(false)}}
                className={activeRoute('map')}
              >
                <i className='fa fa-home' />
                {/* Əsas Səhifə */}
                <span className={(!isOpen) ? "custom-d-none" : ""}>Əsas Səhifə</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <div
                className={activeRoute('devices') + " custom-nav-link nav-link"}
                role="button"
                aria-expanded={deviceCollapse}
                data-toggle={isOpen ? 'collapse' : ''}
                aria-controls="devices-tables"

              >
                <i className='fa fa-hdd' />
                {/* Əsas Səhifə */}
                <Link
                  to="/devices"
                  className="custom--link"
                  onClick={e => setDeviceCollapse(true)}
                >
                  <span
                    className={(!isOpen) ? "custom-d-none" : ""}
                  >
                    Cihazlar
                  </span>
                </Link>
                {isOpen && <i
                  className="fa fa-angle-right toggle-icon"
                  onClick={e => {closeCollapse(); setDeviceCollapse(!deviceCollapse)}}
                />}
              </div>
              <Collapse isOpen={deviceCollapse} className={(!isOpen) ? "custom-d-none" : ""} id="devices-tables">
                <Nav className="nav-sm flex-column">
                  <NavItem>
                    <NavLink
                      href="#"
                      className={checkTableActive(TEMPERATURE, activeTable)}
                      onClick={() => setTable(tableActions.tempActive(), location.pathname, history)}
                    >
                      <i className='fas fa-temperature-high' />
                      {/* Əsas Səhifə */}
                      <small>Temperatur - Rütubət - Magnit</small>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="#"
                      className={checkTableActive(CO, activeTable)}
                      onClick={() => setTable(tableActions.coActive(), location.pathname, history)}
                    >
                      <i className='fas fa-gas-pump' />
                      {/* Əsas Səhifə */}
                      <small>CO - CO<sub>2</sub> - O<sub>3</sub></small>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="#"
                      className={checkTableActive(NH, activeTable)}
                      onClick={() => setTable(tableActions.nhActive(), location.pathname, history)}
                    >
                      <i className='fas fa-biohazard' />
                      {/* Əsas Səhifə */}
                      <small>NH<sub>3</sub> - NO<sub>2</sub> - CH<sub>4</sub></small>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="#"
                      className={checkTableActive(VOICE, activeTable)}
                      onClick={() => setTable(tableActions.voiceActive(), location.pathname, history)}
                    >
                      <i className='fas fa-radiation-alt' />
                      {/* Əsas Səhifə */}
                      <small>Səs - PM2.5 - PM10</small>
                    </NavLink>
                  </NavItem>
                </Nav>
              </Collapse>
            </NavItem>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
};

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
};

export default Sidebar;
