export const BASE_URL = 'https://asan.sumaks.az/api/v1/';

export const LOGIN = `${BASE_URL}auth/token/`;
export const REFRESH_TOKEN = `${BASE_URL}auth/token/refresh/`;
export const LOGOUT = `${BASE_URL}auth/logout/`;

export const USER_INFO = `${BASE_URL}user/`;

export const DEVICE_LIST = `${BASE_URL}devices-list/`;

export const WEATHER_API = `${BASE_URL}weather-forecast/`;
export const ICON_URL = "https://openweathermap.org/img/wn/";

export const DATA_URL = `${BASE_URL}devices/`;
