/* eslint import/no-webpack-loader-syntax: off */
import React, { useState, useCallback } from "react";
import { Link } from "react-router-dom";
import Map, { Marker, Popup, FullscreenControl, NavigationControl } from 'react-map-gl';
import { useSelector } from "react-redux";
import mapboxgl from 'mapbox-gl';
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;



const MapBox = () => {

  const devices = useSelector(state => state.device.devices);

  const [infos, setInfos] = useState([]);

  const markerOnclick = useCallback((device, infos) => {
    const infosUpdate = [
      ...infos
    ];

    const currentIndex = infosUpdate.findIndex(current => current.imei === device.imei);
    if (currentIndex === -1) {
      let temperatureIcon = 'fas fa-temperature-high';

      if (device.currentindicator) {
        if (device.currentindicator.temperature >= 30) temperatureIcon = 'fas fa-temperature-hot';
        else if (device.currentindicator.temperature < 30 && device.currentindicator.temperature >= 18) temperatureIcon = 'fas fa-temperature-high';
        else if (device.currentindicator.temperature < 18 && device.currentindicator.temperature > 0) temperatureIcon = 'fas fa-temperature-low';
        else if (device.currentindicator.temperature <= 0) temperatureIcon = 'fas fa-temperature-frigid';
      }

      const current = {
        imei: device.imei,
        name: device.name,
        icon: temperatureIcon,
        temperature: device.currentindicator?.temperature,
        humidity: device.currentindicator?.humidity,
        lng: device.location.lng,
        lat: device.location.lat,
      }
      infosUpdate.push(current);
    } else {
      infosUpdate.splice(currentIndex, 1);
    }
    // console.log(infosUpdate);
    setInfos(infosUpdate);
  }, []);

  const getMarkers = useCallback((devices, infos) => devices.map((device, i) =>
    device.location && <Marker
      longitude={device.location.lng}
      latitude={device.location.lat}
      onClick={e => markerOnclick(device, infos)}
      key={i}
      // anchor='center'
    >
      <img
        src={
          require("assets/img/icons/icons8-sun-behind-small-cloud-32.png").default
        }
        width={35}
        height={35}
        alt='...'
      />
    </Marker>
  ), [markerOnclick]);

  return (
    <Map
      initialViewState={{
        longitude: 47.733211,
        latitude: 40.181847,
        zoom: 6.5
      }}
      style={{ height: 600 }}
      mapStyle="mapbox://styles/mapbox/streets-v11"
      mapboxAccessToken={process.env.REACT_APP_MAPBOX_KEY}
      locale='az'
    >
      <FullscreenControl />
      <NavigationControl />
      {getMarkers(devices, infos)}
      {infos.map((item, index) =>
        <Popup
          closeOnClick={false}
          longitude={item.lng}
          latitude={item.lat}
          key={index}
          closeButton={true}
          onClose={e => markerOnclick(item, infos)}
        >
          <Link className="text-default" to={"/devices/" + item.imei}>
            <div className="text-center">
              <h4>{item.name}</h4>
              <p className="text-sm">
                <i className={item.icon + " text-danger mr-2"}></i>
                {item.temperature}&#8451;
                <i className="fas fa-humidity text-info ml-3 mr-2">{item.humidity}&#37;</i>
              </p>
            </div>
          </Link>
        </Popup>
      )}
    </Map>
  );
}

export default MapBox;
