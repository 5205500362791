
import React from "react";
import { Container, Row } from "reactstrap";


const Auth = (props) => {
  const mainContent = React.useRef(null);

  React.useEffect(() => {
    document.body.classList.add("bg-default");
    return () => {
      document.body.classList.remove("bg-default");
    };
  }, []);

  return (
    <>
      <div className="main-content" ref={mainContent}>
        {/* <AuthNavbar /> */}
        <div className="header bg-gradient-info py-6 py-lg-7 mb-5">
          <Container>
            <div className="header-body text-center mb-7">
              <img
                alt="..."
                src={
                  require("assets/img/logo/white.svg")
                    .default
                }
                width={200}
              />
            </div>
          </Container>
          <div className="separator separator-bottom separator-skew zindex-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-default"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>
        </div>
        {/* Page content */}
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            {props.children}
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Auth;
