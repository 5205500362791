import { TEMPERATURE, CO, NH, VOICE } from 'store/table-slice';
import moment from 'moment';
moment.locale('az')
const table1Content = (data) => data.map(item => (
  <tr key={'table-1-' + item.id}>
    <td>{item.temperature}</td>
    <td>{item.humidity}</td>
    <td>{item.magnet}</td>
    <td className='text-capitalize'>{moment(Date.parse(item.created_at)).format('D MMMM YYYY, H:mm')}</td>
  </tr>
));

const table2Content = (data) => data.map(item => (
  <tr key={'table-2-' + item.id}>
    <td>{item.carbonmonoksid}</td>
    <td>{item.carbondioksid}</td>
    <td>{item.ozon}</td>
    <td className='text-capitalize'>{moment(Date.parse(item.created_at)).format('D MMMM YYYY, H:mm')}</td>
  </tr>
));

const table3Content = (data) => data.map(item => (
  <tr key={'table-3-' + item.id}>
    <td>{item.ammonia}</td>
    <td>{item.azotdioksid}</td>
    <td>{item.metan}</td>
    <td className='text-capitalize'>{moment(Date.parse(item.created_at)).format('D MMMM YYYY, H:mm')}</td>
  </tr>
));

const table4Content = (data) => data.map(item => (
  <tr key={'table-4-' + item.id}>
    <th>{item.sound}</th>
    <th>{item.pm2_5}</th>
    <th>{item.pm10}</th>
    <td className='text-capitalize'>{moment(Date.parse(item.created_at)).format('D MMMM YYYY, H:mm')}</td>
  </tr>
));


export const getTableHeader = (activeTable, data) => {

  let tableContent, header, tableHeader;

  switch (activeTable) {
    case TEMPERATURE:
      tableContent = table1Content(data);
      header = "Temperatur - Rütubət - Magnit";
      tableHeader = <tr>
        <th>Temperatur (&#8451;)</th>
        <th>Rütubət (&#37;)</th>
        <th>Magnit (mG)</th>
        <th>Tarix</th>
      </tr>;
      break;
    case CO:
      tableContent = table2Content(data);
      header = <>CO - CO<sub>2</sub> - O<sub>3</sub></>;
      tableHeader = <tr>
        <th>Karbon-Monoksid (PPM, saat)</th>
        <th>Karbon-Dioksid (PPM, saat)</th>
        <th>Ozon (PPM)</th>
        <th>Tarix</th>
      </tr>;
      break;
    case NH:
      tableContent = table3Content(data);
      header = <>NH<sub>3</sub> - NO<sub>2</sub> - CH<sub>4</sub></>;
      tableHeader = <tr>
        <th>Ammonyak (PPM)</th>
        <th>Azot-Dioksid (PPM, saat)</th>
        <th>Metan (PPM)</th>
        <th>Tarix</th>
      </tr>;
      break;
    case VOICE:
      tableContent = table4Content(data);
      header = "Səs - PM2.5 - PM10";
      tableHeader = <tr>
        <th>Səs (dB)</th>
        <th>PM2.5 (µg/m<sup>3</sup>, sutka)</th>
        <th>PM10 (µg/m<sup>3</sup>, sutka)</th>
        <th>Tarix</th>
      </tr>;
      break;
    default:
      break;
  }

  return {
    tableContent, header, tableHeader
  }
}
