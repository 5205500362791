
import React from 'react';
import { Container} from "reactstrap";
import { useSelector } from "react-redux";

import Carousel from "react-multi-carousel";
import DeviceCard from "./DeviceCard";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 4
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1
  }
};

const Header = React.memo(() => {
  const devices = useSelector(state => state.device.devices);

  const cards = devices.map((device) => DeviceCard({device})).flat(1);
  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            <Carousel
              responsive={responsive}
              swipeable={false}
              draggable={false}
              showDots={false}
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={5000}
              arrows={false}
              focusOnSelect={true}
            >
              {cards}
            </Carousel>
          </div>
        </Container>
      </div>
    </>
  );
});

export default Header;
