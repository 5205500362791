import React from "react"
import {
  Card,
  CardBody,
  Row,
  Col,
  CardTitle,
} from 'reactstrap'
import Weather from "components/Device/Weather"
import { useDeviceDetail } from "store/device-fetch"
import {useSelector} from 'react-redux'

const Detail = (props) => {

  const { device, currentIndicator, yesterdayIndicator } = useDeviceDetail()
  const norms = useSelector(state => state.norm.norms)

  let temperatureIcon = 'fas fa-temperature-high';
  if (currentIndicator) {
    if (currentIndicator.temperature >= 30) temperatureIcon = 'fas fa-temperature-hot';
    else if (currentIndicator.temperature < 30 && currentIndicator.temperature >= 18) temperatureIcon = 'fas fa-temperature-high';
    else if (currentIndicator.temperature < 18 && currentIndicator.temperature > 0) temperatureIcon = 'fas fa-temperature-low';
    else if (currentIndicator.temperature <= 0) temperatureIcon = 'fas fa-temperature-frigid';
  }
  let temperatureDiff, humidityDiff, magnetDiff, carbonmonoksidDiff, carbondioksidDiff, ozonDiff
  let  ammoniaDiff, azotdioksidDiff, metanDiff, soundDiff, pm2_5Diff, pm10Diff

  let bigWeatherDiv = false

  if(currentIndicator) {
    if (norms && norms?.temperature !== null) {
      bigWeatherDiv = true
      const lastDayTemp = Math.round(norms.temperature * 10) / 10
      if (lastDayTemp > currentIndicator.temperature) {
        temperatureDiff = <p className="mt-3 mb-0 text-sm text-white" title={norms.description}>
          <span className="mr-2"><i className="fa fa-arrow-circle-down"></i> {Math.round((lastDayTemp - currentIndicator.temperature) * 10) / 10} &#8451;</span>
          <span className="text-nowrap">{norms.name}: {lastDayTemp} &#8451;</span>
        </p>
      } else if (lastDayTemp < currentIndicator.temperature) {
        temperatureDiff = <p className="mt-3 mb-0 text-sm text-white" title={norms.description}>
          <span className="mr-2"><i className="fa fa-arrow-circle-up"></i> {Math.round((currentIndicator.temperature - lastDayTemp) * 10) / 10} &#8451;</span>
          <span className="text-nowrap">{norms.name}: {lastDayTemp} &#8451;</span>
        </p>
      } else {
        temperatureDiff = <p className="mt-3 mb-0 text-sm text-white" title={norms.description}>
          <span className="mr-2"><i className="fa fa-minus-circle"></i></span>
          <span className="text-nowrap">{norms.name}: {lastDayTemp} &#8451;</span>
        </p>
      }
    }
    else if(yesterdayIndicator && yesterdayIndicator?.temperature !== null) {
      bigWeatherDiv = true
      const lastDayTemp = Math.round(yesterdayIndicator.temperature * 10) / 10
      if (lastDayTemp > currentIndicator.temperature) {
        temperatureDiff = <p className="mt-3 mb-0 text-sm text-white" title="Son göstərici ilə eyni saat aralığına uyğun bir gün əvvəlki orta göstərici ilə müqayisə">
          <span className="mr-2"><i className="fa fa-arrow-circle-down"></i> {Math.round((lastDayTemp - currentIndicator.temperature) * 10) / 10} &#8451;</span>
          <span className="text-nowrap">Bir gün əvvəl: {lastDayTemp} &#8451;</span>
        </p>
      } else if (lastDayTemp < currentIndicator.temperature) {
        temperatureDiff = <p className="mt-3 mb-0 text-sm text-white" title="Son göstərici ilə eyni saat aralığına uyğun bir gün əvvəlki orta göstərici ilə müqayisə">
          <span className="mr-2"><i className="fa fa-arrow-circle-up"></i> {Math.round((currentIndicator.temperature - lastDayTemp) * 10) / 10} &#8451;</span>
          <span className="text-nowrap">Bir gün əvvəl: {lastDayTemp} &#8451;</span>
        </p>
      } else {
        temperatureDiff = <p className="mt-3 mb-0 text-sm text-white" title="Son göstərici ilə eyni saat aralığına uyğun bir gün əvvəlki orta göstərici ilə müqayisə">
          <span className="mr-2"><i className="fa fa-minus-circle"></i></span>
          <span className="text-nowrap">Bir gün əvvəl: {lastDayTemp} &#8451;</span>
        </p>
      }
    }
    if (norms && norms?.humidity !== null) {
      bigWeatherDiv = true
      const lastDayHumd = Math.round(norms.humidity * 10) / 10
      if (lastDayHumd > currentIndicator.humidity) {
        humidityDiff = <p className="mt-3 mb-0 text-sm text-white" title={norms.description}>
          <span className="mr-2"><i className="fa fa-arrow-circle-down"></i> {Math.round((lastDayHumd - currentIndicator.humidity) * 10) / 10} &#37;</span>
          <span className="text-nowrap">{norms.name}: {lastDayHumd} &#37;</span>
        </p>
      } else if (lastDayHumd < currentIndicator.humidity) {
        humidityDiff = <p className="mt-3 mb-0 text-sm text-white" title={norms.description}>
          <span className="mr-2"><i className="fa fa-arrow-circle-up"></i> {Math.round((currentIndicator.humidity - lastDayHumd) * 10) / 10} &#37;</span>
          <span className="text-nowrap">{norms.name}: {lastDayHumd} &#37;</span>
        </p>
      } else {
        humidityDiff = <p className="mt-3 mb-0 text-sm text-white" title={norms.description}>
          <span className="mr-2"><i className="fa fa-minus-circle"></i></span>
          <span className="text-nowrap">{norms.name}: {lastDayHumd} &#37;</span>
        </p>
      }
    }
    else if(yesterdayIndicator && yesterdayIndicator?.humidity !== null) {
      bigWeatherDiv = true
      const lastDayHumd = Math.round(yesterdayIndicator.humidity * 10) / 10
      if (lastDayHumd > currentIndicator.humidity) {
        humidityDiff = <p className="mt-3 mb-0 text-sm text-white" title="Son göstərici ilə eyni saat aralığına uyğun bir gün əvvəlki orta göstərici ilə müqayisə">
          <span className="mr-2"><i className="fa fa-arrow-circle-down"></i> {Math.round((lastDayHumd - currentIndicator.humidity) * 10) / 10} &#37;</span>
          <span className="text-nowrap">Bir gün əvvəl: {lastDayHumd} &#37;</span>
        </p>
      } else if (lastDayHumd < currentIndicator.humidity) {
        humidityDiff = <p className="mt-3 mb-0 text-sm text-white" title="Son göstərici ilə eyni saat aralığına uyğun bir gün əvvəlki orta göstərici ilə müqayisə">
          <span className="mr-2"><i className="fa fa-arrow-circle-up"></i> {Math.round((currentIndicator.humidity - lastDayHumd) * 10) / 10} &#37;</span>
          <span className="text-nowrap">Bir gün əvvəl: {lastDayHumd} &#37;</span>
        </p>
      } else {
        humidityDiff = <p className="mt-3 mb-0 text-sm text-white" title="Son göstərici ilə eyni saat aralığına uyğun bir gün əvvəlki orta göstərici ilə müqayisə">
          <span className="mr-2"><i className="fa fa-minus-circle"></i></span>
          <span className="text-nowrap">Bir gün əvvəl: {lastDayHumd} &#37;</span>
        </p>
      }
    }

    if (norms && norms?.magnet !== null) {
      bigWeatherDiv = true
      const lastDayMag = Math.round(norms.magnet * 10) / 10
      if (lastDayMag > currentIndicator.magnet) {
        magnetDiff = <p className="mt-3 mb-0 text-sm text-white" title={norms.description}>
          <span className="mr-2"><i className="fa fa-arrow-circle-down"></i> {Math.round((lastDayMag - currentIndicator.magnet) * 10) / 10} mG</span>
          <span className="text-nowrap">{norms.name}: {lastDayMag} mG</span>
        </p>
      } else if (lastDayMag < currentIndicator.magnet) {
        magnetDiff = <p className="mt-3 mb-0 text-sm text-white" title={norms.description}>
          <span className="mr-2"><i className="fa fa-arrow-circle-up"></i> {Math.round((currentIndicator.magnet - lastDayMag) * 10) / 10} mG</span>
          <span className="text-nowrap">{norms.name}: {lastDayMag} mG</span>
        </p>
      } else {
        magnetDiff = <p className="mt-3 mb-0 text-sm text-white" title={norms.description}>
          <span className="mr-2"><i className="fa fa-minus-circle"></i></span>
          <span className="text-nowrap">{norms.name}: {lastDayMag} mG</span>
        </p>
      }
    }
    else if(yesterdayIndicator && yesterdayIndicator?.magnet !== null) {
      bigWeatherDiv = true
      const lastDayMag = Math.round(yesterdayIndicator.magnet * 10) / 10
      if (lastDayMag > currentIndicator.magnet) {
        magnetDiff = <p className="mt-3 mb-0 text-sm text-white" title="Son göstərici ilə eyni saat aralığına uyğun bir gün əvvəlki orta göstərici ilə müqayisə">
          <span className="mr-2"><i className="fa fa-arrow-circle-down"></i> {Math.round((lastDayMag - currentIndicator.magnet) * 10) / 10} mG</span>
          <span className="text-nowrap">Bir gün əvvəl: {lastDayMag} mG</span>
        </p>
      } else if (lastDayMag < currentIndicator.magnet) {
        magnetDiff = <p className="mt-3 mb-0 text-sm text-white" title="Son göstərici ilə eyni saat aralığına uyğun bir gün əvvəlki orta göstərici ilə müqayisə">
          <span className="mr-2"><i className="fa fa-arrow-circle-up"></i> {Math.round((currentIndicator.magnet - lastDayMag) * 10) / 10} mG</span>
          <span className="text-nowrap">Bir gün əvvəl: {lastDayMag} mG</span>
        </p>
      } else {
        magnetDiff = <p className="mt-3 mb-0 text-sm text-white" title="Son göstərici ilə eyni saat aralığına uyğun bir gün əvvəlki orta göstərici ilə müqayisə">
          <span className="mr-2"><i className="fa fa-minus-circle"></i></span>
          <span className="text-nowrap">Bir gün əvvəl: {lastDayMag} mG</span>
        </p>
      }
    }

    if (norms && norms?.carbonmonoksid !== null ) {
      bigWeatherDiv = true
      const lastDayCO = Math.round(norms.carbonmonoksid * 10000) / 10000
      if (lastDayCO > currentIndicator.carbonmonoksid) {
        carbonmonoksidDiff = <p className="mt-3 mb-0 text-sm text-white" title={norms.description}>
          <span className="mr-2"><i className="fa fa-arrow-circle-down"></i> {Math.round((lastDayCO - currentIndicator.carbonmonoksid) * 10000) / 10000} PPM</span>
          <span className="text-nowrap">{norms.name}: {lastDayCO} PPM</span>
        </p>
      } else if (lastDayCO < currentIndicator.carbonmonoksid) {
        carbonmonoksidDiff = <p className="mt-3 mb-0 text-sm text-white" title={norms.description}>
          <span className="mr-2"><i className="fa fa-arrow-circle-up"></i> {Math.round((currentIndicator.carbonmonoksid - lastDayCO) * 10000) / 10000} PPM</span>
          <span className="text-nowrap">{norms.name}: {lastDayCO} PPM</span>
        </p>
      } else {
        carbonmonoksidDiff = <p className="mt-3 mb-0 text-sm text-white" title={norms.description}>
          <span className="mr-2"><i className="fa fa-minus-circle"></i></span>
          <span className="text-nowrap">{norms.name}: {lastDayCO} PPM</span>
        </p>
      }
    }
    else if(yesterdayIndicator && yesterdayIndicator?.carbonmonoksid !== null) {
      bigWeatherDiv = true
      const lastDayCO = Math.round(yesterdayIndicator.carbonmonoksid * 10000) / 10000
      if (lastDayCO > currentIndicator.carbonmonoksid) {
        carbonmonoksidDiff = <p className="mt-3 mb-0 text-sm text-white" title="Son göstərici ilə eyni saat aralığına uyğun bir gün əvvəlki orta göstərici ilə müqayisə">
          <span className="mr-2"><i className="fa fa-arrow-circle-down"></i> {Math.round((lastDayCO - currentIndicator.carbonmonoksid) * 10000) / 10000} PPM</span>
          <span className="text-nowrap">Bir gün əvvəl: {lastDayCO} PPM</span>
        </p>
      } else if (lastDayCO < currentIndicator.carbonmonoksid) {
        carbonmonoksidDiff = <p className="mt-3 mb-0 text-sm text-white" title="Son göstərici ilə eyni saat aralığına uyğun bir gün əvvəlki orta göstərici ilə müqayisə">
          <span className="mr-2"><i className="fa fa-arrow-circle-up"></i> {Math.round((currentIndicator.carbonmonoksid - lastDayCO) * 10000) / 10000} PPM</span>
          <span className="text-nowrap">Bir gün əvvəl: {lastDayCO} PPM</span>
        </p>
      } else {
        carbonmonoksidDiff = <p className="mt-3 mb-0 text-sm text-white" title="Son göstərici ilə eyni saat aralığına uyğun bir gün əvvəlki orta göstərici ilə müqayisə">
          <span className="mr-2"><i className="fa fa-minus-circle"></i></span>
          <span className="text-nowrap">Bir gün əvvəl: {lastDayCO} PPM</span>
        </p>
      }
    }

    if (norms && norms?.carbondioksid !== null) {
      const lastDayCO2 = Math.round(norms.carbondioksid * 10000) / 10000
      if (lastDayCO2 > currentIndicator.carbondioksid) {
        carbondioksidDiff = <p className="mt-3 mb-0 text-sm text-white" title={norms.description}>
          <span className="mr-2"><i className="fa fa-arrow-circle-down"></i> {Math.round((lastDayCO2 - currentIndicator.carbondioksid) * 10000) / 10000} PPM</span>
          <span className="text-nowrap">{norms.name}: {lastDayCO2} PPM</span>
        </p>
      } else if (lastDayCO2 < currentIndicator.carbondioksid) {
        carbondioksidDiff = <p className="mt-3 mb-0 text-sm text-white" title={norms.description}>
          <span className="mr-2"><i className="fa fa-arrow-circle-up"></i> {Math.round((currentIndicator.carbondioksid - lastDayCO2) * 10000) / 10000} PPM</span>
          <span className="text-nowrap">{norms.name}: {lastDayCO2} PPM</span>
        </p>
      } else {
        carbondioksidDiff = <p className="mt-3 mb-0 text-sm text-white" title={norms.description}>
          <span className="mr-2"><i className="fa fa-minus-circle"></i></span>
          <span className="text-nowrap">{norms.name}: {lastDayCO2} PPM</span>
        </p>
      }
    }
    else if(yesterdayIndicator && yesterdayIndicator?.carbondioksid !== null) {
      const lastDayCO2 = Math.round(yesterdayIndicator.carbondioksid * 10000) / 10000
      if (lastDayCO2 > currentIndicator.carbondioksid) {
        carbondioksidDiff = <p className="mt-3 mb-0 text-sm text-white" title="Son göstərici ilə eyni saat aralığına uyğun bir gün əvvəlki orta göstərici ilə müqayisə">
          <span className="mr-2"><i className="fa fa-arrow-circle-down"></i> {Math.round((lastDayCO2 - currentIndicator.carbondioksid) * 10000) / 10000} PPM</span>
          <span className="text-nowrap">Bir gün əvvəl: {lastDayCO2} PPM</span>
        </p>
      } else if (lastDayCO2 < currentIndicator.carbondioksid) {
        carbondioksidDiff = <p className="mt-3 mb-0 text-sm text-white" title="Son göstərici ilə eyni saat aralığına uyğun bir gün əvvəlki orta göstərici ilə müqayisə">
          <span className="mr-2"><i className="fa fa-arrow-circle-up"></i> {Math.round((currentIndicator.carbondioksid - lastDayCO2) * 10000) / 10000} PPM</span>
          <span className="text-nowrap">Bir gün əvvəl: {lastDayCO2} PPM</span>
        </p>
      } else {
        carbondioksidDiff = <p className="mt-3 mb-0 text-sm text-white" title="Son göstərici ilə eyni saat aralığına uyğun bir gün əvvəlki orta göstərici ilə müqayisə">
          <span className="mr-2"><i className="fa fa-minus-circle"></i></span>
          <span className="text-nowrap">Bir gün əvvəl: {lastDayCO2} PPM</span>
        </p>
      }
    }

    if (norms && norms?.ozon !== null) {
      const lastDayOzon = Math.round(norms.ozon * 10000) / 10000
      if (lastDayOzon > currentIndicator.ozon) {
        ozonDiff = <p className="mt-3 mb-0 text-sm text-white" title={norms.description}>
          <span className="mr-2"><i className="fa fa-arrow-circle-down"></i> {Math.round((lastDayOzon - currentIndicator.ozon) * 10000) / 10000} PPM</span>
          <span className="text-nowrap">{norms.name}: {lastDayOzon} PPM</span>
        </p>
      } else if (lastDayOzon < currentIndicator.ozon) {
        ozonDiff = <p className="mt-3 mb-0 text-sm text-white" title={norms.description}>
          <span className="mr-2"><i className="fa fa-arrow-circle-up"></i> {Math.round((currentIndicator.ozon - lastDayOzon) * 10000) / 10000} PPM</span>
          <span className="text-nowrap">{norms.name}:{lastDayOzon} PPM</span>
        </p>
      } else {
        ozonDiff = <p className="mt-3 mb-0 text-sm text-white" title={norms.description}>
          <span className="mr-2"><i className="fa fa-minus-circle"></i></span>
          <span className="text-nowrap">{norms.name}: {lastDayOzon} PPM</span>
        </p>
      }
    }
    else if(yesterdayIndicator && yesterdayIndicator?.ozon !== null) {
      const lastDayOzon = Math.round(yesterdayIndicator.ozon * 10000) / 10000
      if (lastDayOzon > currentIndicator.ozon) {
        ozonDiff = <p className="mt-3 mb-0 text-sm text-white" title="Son göstərici ilə eyni saat aralığına uyğun bir gün əvvəlki orta göstərici ilə müqayisə">
          <span className="mr-2"><i className="fa fa-arrow-circle-down"></i> {Math.round((lastDayOzon - currentIndicator.ozon) * 10000) / 10000} PPM</span>
          <span className="text-nowrap">Bir gün əvvəl: {lastDayOzon} PPM</span>
        </p>
      } else if (lastDayOzon < currentIndicator.ozon) {
        ozonDiff = <p className="mt-3 mb-0 text-sm text-white" title="Son göstərici ilə eyni saat aralığına uyğun bir gün əvvəlki orta göstərici ilə müqayisə">
          <span className="mr-2"><i className="fa fa-arrow-circle-up"></i> {Math.round((currentIndicator.ozon - lastDayOzon) * 10000) / 10000} PPM</span>
          <span className="text-nowrap">Bir gün əvvəl:{lastDayOzon} PPM</span>
        </p>
      } else {
        ozonDiff = <p className="mt-3 mb-0 text-sm text-white" title="Son göstərici ilə eyni saat aralığına uyğun bir gün əvvəlki orta göstərici ilə müqayisə">
          <span className="mr-2"><i className="fa fa-minus-circle"></i></span>
          <span className="text-nowrap">Bir gün əvvəl: {lastDayOzon} PPM</span>
        </p>
      }
    }

    if (norms && norms?.ammonia !== null) {
      const lastDayAmmon = Math.round(norms.ammonia * 10000) / 10000
      if (lastDayAmmon > currentIndicator.ammonia) {
        ammoniaDiff = <p className="mt-3 mb-0 text-sm text-white" title={norms.description}>
          <span className="mr-2"><i className="fa fa-arrow-circle-down"></i> {Math.round((lastDayAmmon - currentIndicator.ammonia) * 10000) / 10000} PPM</span>
          <span className="text-nowrap">{norms.name}: {lastDayAmmon} PPM</span>
        </p>
      } else if (lastDayAmmon < currentIndicator.ammonia) {
        ammoniaDiff = <p className="mt-3 mb-0 text-sm text-white" title={norms.description}>
          <span className="mr-2"><i className="fa fa-arrow-circle-up"></i> {Math.round((currentIndicator.ammonia - lastDayAmmon) * 10000) / 10000} PPM</span>
          <span className="text-nowrap">{norms.name}: {lastDayAmmon} PPM</span>
        </p>
      } else {
        ammoniaDiff = <p className="mt-3 mb-0 text-sm text-white" title={norms.description}>
          <span className="mr-2"><i className="fa fa-minus-circle"></i></span>
          <span className="text-nowrap">{norms.name}: {lastDayAmmon} PPM</span>
        </p>
      }
    }
    else if(yesterdayIndicator && yesterdayIndicator?.ammonia !== null) {
      const lastDayAmmon = Math.round(yesterdayIndicator.ammonia * 10000) / 10000
      if (lastDayAmmon > currentIndicator.ammonia) {
        ammoniaDiff = <p className="mt-3 mb-0 text-sm text-white" title="Son göstərici ilə eyni saat aralığına uyğun bir gün əvvəlki orta göstərici ilə müqayisə">
          <span className="mr-2"><i className="fa fa-arrow-circle-down"></i> {Math.round((lastDayAmmon - currentIndicator.ammonia) * 10000) / 10000} PPM</span>
          <span className="text-nowrap">Bir gün əvvəl: {lastDayAmmon} PPM</span>
        </p>
      } else if (lastDayAmmon < currentIndicator.ammonia) {
        ammoniaDiff = <p className="mt-3 mb-0 text-sm text-white" title="Son göstərici ilə eyni saat aralığına uyğun bir gün əvvəlki orta göstərici ilə müqayisə">
          <span className="mr-2"><i className="fa fa-arrow-circle-up"></i> {Math.round((currentIndicator.ammonia - lastDayAmmon) * 10000) / 10000} PPM</span>
          <span className="text-nowrap">Bir gün əvvəl: {lastDayAmmon} PPM</span>
        </p>
      } else {
        ammoniaDiff = <p className="mt-3 mb-0 text-sm text-white" title="Son göstərici ilə eyni saat aralığına uyğun bir gün əvvəlki orta göstərici ilə müqayisə">
          <span className="mr-2"><i className="fa fa-minus-circle"></i></span>
          <span className="text-nowrap">Bir gün əvvəl: {lastDayAmmon} PPM</span>
        </p>
      }
    }

    if (norms && norms?.azotdioksid !== null) {
      const lastDayAzot = Math.round(norms.azotdioksid * 10000) / 10000
      if (lastDayAzot > currentIndicator.azotdioksid) {
        azotdioksidDiff = <p className="mt-3 mb-0 text-sm text-white" title={norms.description}>
          <span className="mr-2"><i className="fa fa-arrow-circle-down"></i> {Math.round((lastDayAzot - currentIndicator.azotdioksid) * 10000) / 10000} PPM</span>
          <span className="text-nowrap">{norms.name}: {lastDayAzot} PPM</span>
        </p>
      } else if (lastDayAzot < currentIndicator.azotdioksid) {
        azotdioksidDiff = <p className="mt-3 mb-0 text-sm text-white" title={norms.description}>
          <span className="mr-2"><i className="fa fa-arrow-circle-up"></i> {Math.round((currentIndicator.azotdioksid - lastDayAzot) * 10000) / 10000} PPM</span>
          <span className="text-nowrap">{norms.name}: {lastDayAzot} PPM</span>
        </p>
      } else {
        azotdioksidDiff = <p className="mt-3 mb-0 text-sm text-white" title={norms.description}>
          <span className="mr-2"><i className="fa fa-minus-circle"></i></span>
          <span className="text-nowrap">{norms.name}: {lastDayAzot} PPM</span>
        </p>
      }
    }
    else if(yesterdayIndicator && yesterdayIndicator?.azotdioksid !== null) {
      const lastDayAzot = Math.round(yesterdayIndicator.azotdioksid * 10000) / 10000
      if (lastDayAzot > currentIndicator.azotdioksid) {
        azotdioksidDiff = <p className="mt-3 mb-0 text-sm text-white" title="Son göstərici ilə eyni saat aralığına uyğun bir gün əvvəlki orta göstərici ilə müqayisə">
          <span className="mr-2"><i className="fa fa-arrow-circle-down"></i> {Math.round((lastDayAzot - currentIndicator.azotdioksid) * 10000) / 10000} PPM</span>
          <span className="text-nowrap">Bir gün əvvəl: {lastDayAzot} PPM</span>
        </p>
      } else if (lastDayAzot < currentIndicator.azotdioksid) {
        azotdioksidDiff = <p className="mt-3 mb-0 text-sm text-white" title="Son göstərici ilə eyni saat aralığına uyğun bir gün əvvəlki orta göstərici ilə müqayisə">
          <span className="mr-2"><i className="fa fa-arrow-circle-up"></i> {Math.round((currentIndicator.azotdioksid - lastDayAzot) * 10000) / 10000} PPM</span>
          <span className="text-nowrap">Bir gün əvvəl: {lastDayAzot} PPM</span>
        </p>
      } else {
        azotdioksidDiff = <p className="mt-3 mb-0 text-sm text-white" title="Son göstərici ilə eyni saat aralığına uyğun bir gün əvvəlki orta göstərici ilə müqayisə">
          <span className="mr-2"><i className="fa fa-minus-circle"></i></span>
          <span className="text-nowrap">Bir gün əvvəl: {lastDayAzot} PPM</span>
        </p>
      }
    }


    if (norms && norms?.metan !== null) {
      const lastDayMetan = Math.round(norms.metan * 10000) / 10000
      if (lastDayMetan > currentIndicator.metan) {
        metanDiff = <p className="mt-3 mb-0 text-sm text-white" title={norms.description}>
          <span className="mr-2"><i className="fa fa-arrow-circle-down"></i> {Math.round((lastDayMetan - currentIndicator.metan) * 10000) / 10000} PPM</span>
          <span className="text-nowrap">{norms.name}: {lastDayMetan} PPM</span>
        </p>
      } else if (lastDayMetan < currentIndicator.metan) {
        metanDiff = <p className="mt-3 mb-0 text-sm text-white" title={norms.description}>
          <span className="mr-2"><i className="fa fa-arrow-circle-up"></i> {Math.round((currentIndicator.metan - lastDayMetan) * 10000) / 10000} PPM</span>
          <span className="text-nowrap">{norms.name}: {lastDayMetan} PPM</span>
        </p>
      } else {
        metanDiff = <p className="mt-3 mb-0 text-sm text-white" title={norms.description}>
          <span className="mr-2"><i className="fa fa-minus-circle"></i></span>
          <span className="text-nowrap">{norms.name}: {lastDayMetan} PPM</span>
        </p>
      }
    }
    else if(yesterdayIndicator && yesterdayIndicator?.metan !== null) {
      const lastDayMetan = Math.round(yesterdayIndicator.metan * 10000) / 10000
      if (lastDayMetan > currentIndicator.metan) {
        metanDiff = <p className="mt-3 mb-0 text-sm text-white" title="Son göstərici ilə eyni saat aralığına uyğun bir gün əvvəlki orta göstərici ilə müqayisə">
          <span className="mr-2"><i className="fa fa-arrow-circle-down"></i> {Math.round((lastDayMetan - currentIndicator.metan) * 10000) / 10000} PPM</span>
          <span className="text-nowrap">Bir gün əvvəl: {lastDayMetan} PPM</span>
        </p>
      } else if (lastDayMetan < currentIndicator.metan) {
        metanDiff = <p className="mt-3 mb-0 text-sm text-white" title="Son göstərici ilə eyni saat aralığına uyğun bir gün əvvəlki orta göstərici ilə müqayisə">
          <span className="mr-2"><i className="fa fa-arrow-circle-up"></i> {Math.round((currentIndicator.metan - lastDayMetan) * 10000) / 10000} PPM</span>
          <span className="text-nowrap">Bir gün əvvəl: {lastDayMetan} PPM</span>
        </p>
      } else {
        metanDiff = <p className="mt-3 mb-0 text-sm text-white" title="Son göstərici ilə eyni saat aralığına uyğun bir gün əvvəlki orta göstərici ilə müqayisə">
          <span className="mr-2"><i className="fa fa-minus-circle"></i></span>
          <span className="text-nowrap">Bir gün əvvəl: {lastDayMetan} PPM</span>
        </p>
      }
    }

    if (norms && norms?.sound !== null) {
      const lastDaySound = Math.round(norms.sound * 10) / 10
      if (lastDaySound > currentIndicator.sound) {
        soundDiff = <p className="mt-3 mb-0 text-sm text-white" title={norms.description}>
          <span className="mr-2"><i className="fa fa-arrow-circle-down"></i> {Math.round((lastDaySound - currentIndicator.sound) * 10) / 10} dB</span>
          <span className="text-nowrap">{norms.name}: {lastDaySound} dB</span>
        </p>
      } else if (lastDaySound < currentIndicator.sound) {
        soundDiff = <p className="mt-3 mb-0 text-sm text-white" title={norms.description}>
          <span className="mr-2"><i className="fa fa-arrow-circle-up"></i> {Math.round((currentIndicator.sound - lastDaySound) * 10) / 10} dB</span>
          <span className="text-nowrap">{norms.name}: {lastDaySound} dB</span>
        </p>
      } else {
        soundDiff = <p className="mt-3 mb-0 text-sm text-white" title={norms.description}>
          <span className="mr-2"><i className="fa fa-minus-circle"></i></span>
          <span className="text-nowrap">{norms.name}: {lastDaySound} dB</span>
        </p>
      }
    }
    else if(yesterdayIndicator && yesterdayIndicator?.sound !== null) {
      const lastDaySound = Math.round(yesterdayIndicator.sound * 10) / 10
      if (lastDaySound > currentIndicator.sound) {
        soundDiff = <p className="mt-3 mb-0 text-sm text-white" title="Son göstərici ilə eyni saat aralığına uyğun bir gün əvvəlki orta göstərici ilə müqayisə">
          <span className="mr-2"><i className="fa fa-arrow-circle-down"></i> {Math.round((lastDaySound - currentIndicator.sound) * 10) / 10} dB</span>
          <span className="text-nowrap">Bir gün əvvəl: {lastDaySound} dB</span>
        </p>
      } else if (lastDaySound < currentIndicator.sound) {
        soundDiff = <p className="mt-3 mb-0 text-sm text-white" title="Son göstərici ilə eyni saat aralığına uyğun bir gün əvvəlki orta göstərici ilə müqayisə">
          <span className="mr-2"><i className="fa fa-arrow-circle-up"></i> {Math.round((currentIndicator.sound - lastDaySound) * 10) / 10} dB</span>
          <span className="text-nowrap">Bir gün əvvəl: {lastDaySound} dB</span>
        </p>
      } else {
        soundDiff = <p className="mt-3 mb-0 text-sm text-white" title="Son göstərici ilə eyni saat aralığına uyğun bir gün əvvəlki orta göstərici ilə müqayisə">
          <span className="mr-2"><i className="fa fa-minus-circle"></i></span>
          <span className="text-nowrap">Bir gün əvvəl: {lastDaySound} dB</span>
        </p>
      }
    }

    if (norms && norms?.pm2_5 !== null) {
      const lastDayPm25 = Math.round(norms.pm2_5 * 10) / 10
      if (lastDayPm25 > currentIndicator.pm2_5) {
        pm2_5Diff = <p className="mt-3 mb-0 text-sm text-white" title={norms.description}>
          <span className="mr-2"><i className="fa fa-arrow-circle-down"></i> {Math.round((lastDayPm25 - currentIndicator.pm2_5) * 10) / 10} µg/m<sup>3</sup></span>
          <span className="text-nowrap">{norms.name}: {lastDayPm25} µg/m<sup>3</sup></span>
        </p>
      } else if (lastDayPm25 < currentIndicator.pm2_5) {
        pm2_5Diff = <p className="mt-3 mb-0 text-sm text-white" title={norms.description}>
          <span className="mr-2"><i className="fa fa-arrow-circle-up"></i> {Math.round((currentIndicator.pm2_5 - lastDayPm25) * 10) / 10} µg/m<sup>3</sup></span>
          <span className="text-nowrap">{norms.name}: {lastDayPm25} µg/m<sup>3</sup></span>
        </p>
      } else {
        pm2_5Diff = <p className="mt-3 mb-0 text-sm text-white" title={norms.description}>
          <span className="mr-2"><i className="fa fa-minus-circle"></i></span>
          <span className="text-nowrap">{norms.name}: {lastDayPm25} µg/m<sup>3</sup></span>
        </p>
      }
    }
    else if(yesterdayIndicator && yesterdayIndicator?.pm2_5 !== null) {
      const lastDayPm25 = Math.round(yesterdayIndicator.pm2_5 * 10) / 10
      if (lastDayPm25 > currentIndicator.pm2_5) {
        pm2_5Diff = <p className="mt-3 mb-0 text-sm text-white" title="Son göstərici ilə eyni saat aralığına uyğun bir gün əvvəlki orta göstərici ilə müqayisə">
          <span className="mr-2"><i className="fa fa-arrow-circle-down"></i> {Math.round((lastDayPm25 - currentIndicator.pm2_5) * 10) / 10} µg/m<sup>3</sup></span>
          <span className="text-nowrap">Bir gün əvvəl: {lastDayPm25} µg/m<sup>3</sup></span>
        </p>
      } else if (lastDayPm25 < currentIndicator.pm2_5) {
        pm2_5Diff = <p className="mt-3 mb-0 text-sm text-white" title="Son göstərici ilə eyni saat aralığına uyğun bir gün əvvəlki orta göstərici ilə müqayisə">
          <span className="mr-2"><i className="fa fa-arrow-circle-up"></i> {Math.round((currentIndicator.pm2_5 - lastDayPm25) * 10) / 10} µg/m<sup>3</sup></span>
          <span className="text-nowrap">Bir gün əvvəl: {lastDayPm25} µg/m<sup>3</sup></span>
        </p>
      } else {
        pm2_5Diff = <p className="mt-3 mb-0 text-sm text-white" title="Son göstərici ilə eyni saat aralığına uyğun bir gün əvvəlki orta göstərici ilə müqayisə">
          <span className="mr-2"><i className="fa fa-minus-circle"></i></span>
          <span className="text-nowrap">Bir gün əvvəl: {lastDayPm25} µg/m<sup>3</sup></span>
        </p>
      }
    }

    if (norms && norms?.pm10 !== null) {
      const lastDayPm10 = Math.round(norms.pm10 * 10) / 10
      if (lastDayPm10 > currentIndicator.pm10) {
        pm10Diff = <p className="mt-3 mb-0 text-sm text-white" title={norms.description}>
          <span className="mr-2"><i className="fa fa-arrow-circle-down"></i> {Math.round((lastDayPm10 - currentIndicator.pm10) * 10) / 10} µg/m<sup>3</sup></span>
          <span className="text-nowrap">{norms.name}: {lastDayPm10} µg/m<sup>3</sup></span>
        </p>
      } else if (lastDayPm10 < currentIndicator.pm10) {
        pm10Diff = <p className="mt-3 mb-0 text-sm text-white" title={norms.description}>
          <span className="mr-2"><i className="fa fa-arrow-circle-up"></i> {Math.round((currentIndicator.pm10 - lastDayPm10) * 10) / 10} µg/m<sup>3</sup></span>
          <span className="text-nowrap">{norms.name}: {lastDayPm10} µg/m<sup>3</sup></span>
        </p>
      } else {
        pm10Diff = <p className="mt-3 mb-0 text-sm text-white" title={norms.description}>
          <span className="mr-2"><i className="fa fa-minus-circle"></i></span>
          <span className="text-nowrap">{norms.name}: {lastDayPm10} µg/m<sup>3</sup></span>
        </p>
      }
    }
    else if(yesterdayIndicator && yesterdayIndicator?.pm10 !== null) {
      const lastDayPm10 = Math.round(yesterdayIndicator.pm10 * 10) / 10
      if (lastDayPm10 > currentIndicator.pm10) {
        pm10Diff = <p className="mt-3 mb-0 text-sm text-white" title="Son göstərici ilə eyni saat aralığına uyğun bir gün əvvəlki orta göstərici ilə müqayisə">
          <span className="mr-2"><i className="fa fa-arrow-circle-down"></i> {Math.round((lastDayPm10 - currentIndicator.pm10) * 10) / 10} µg/m<sup>3</sup></span>
          <span className="text-nowrap">Bir gün əvvəl: {lastDayPm10} µg/m<sup>3</sup></span>
        </p>
      } else if (lastDayPm10 < currentIndicator.pm10) {
        pm10Diff = <p className="mt-3 mb-0 text-sm text-white" title="Son göstərici ilə eyni saat aralığına uyğun bir gün əvvəlki orta göstərici ilə müqayisə">
          <span className="mr-2"><i className="fa fa-arrow-circle-up"></i> {Math.round((currentIndicator.pm10 - lastDayPm10) * 10) / 10} µg/m<sup>3</sup></span>
          <span className="text-nowrap">Bir gün əvvəl: {lastDayPm10} µg/m<sup>3</sup></span>
        </p>
      } else {
        pm10Diff = <p className="mt-3 mb-0 text-sm text-white" title="Son göstərici ilə eyni saat aralığına uyğun bir gün əvvəlki orta göstərici ilə müqayisə">
          <span className="mr-2"><i className="fa fa-minus-circle"></i></span>
          <span className="text-nowrap">Bir gün əvvəl: {lastDayPm10} µg/m<sup>3</sup></span>
        </p>
      }
    }

  }


  return (
    <Row>
      <Col md="6" className="mb-3">
        <Weather imei={device?.imei} bigDiv={bigWeatherDiv}/>
      </Col>
      <Col md="6" className="mb-3">
        <Row>
          <Col md="6" className="mb-3">
            <Card className="bg-gradient-danger">
              <CardBody>
                <Row>
                  <Col>
                    <CardTitle
                      className="text-uppercase text-muted text-white mb-0"
                    >
                      Temperatur
                    </CardTitle>
                    <span className="h2 font-weight-bold mb-0 text-white">
                      {currentIndicator?.temperature} &#8451;
                    </span>
                  </Col>
                  <Col className="col-auto">
                    <div className="icon icon-shape bg-white text-danger rounded-circle shadow">
                      <i className={temperatureIcon} />
                    </div>
                  </Col>
                </Row>
                {temperatureDiff}
              </CardBody>
            </Card>
          </Col>
          <Col md="6" className="mb-3">
            <Card className="bg-gradient-info">
              <CardBody>
                <Row>
                  <Col>
                    <CardTitle
                      className="text-uppercase text-muted text-white mb-0"
                    >
                      Rütubət
                    </CardTitle>
                    <span className="h2 font-weight-bold mb-0 text-white">
                      {currentIndicator?.humidity} &#37;
                    </span>
                  </Col>
                  <Col className="col-auto">
                    <div className="icon icon-shape bg-white text-info rounded-circle shadow">
                      <i className="fas fa-humidity" />
                    </div>
                  </Col>
                </Row>
                {humidityDiff}
              </CardBody>
            </Card>
          </Col>
          <Col md="6" className="mb-3">
            <Card className="bg-gradient-primary">
              <CardBody>
                <Row>
                  <Col>
                    <CardTitle
                      className="text-uppercase text-muted text-white mb-0"
                    >
                      Magnit
                    </CardTitle>
                    <span className="h2 font-weight-bold mb-0 text-white">
                      {currentIndicator?.magnet} mG
                    </span>
                  </Col>
                  <Col className="col-auto">
                    <div className="icon icon-shape bg-white text-primary rounded-circle shadow">
                      <i className="fas fa-magnet" />
                    </div>
                  </Col>
                </Row>
                {magnetDiff}
              </CardBody>
            </Card>
          </Col>
          <Col md="6" className="mb-3">
            <Card className="bg-gradient-darker">
              <CardBody>
                <Row>
                  <Col>
                    <CardTitle
                      className="text-uppercase text-muted text-white mb-0"
                    >
                      Karbon-monoksid (CO)
                    </CardTitle>
                    <span className="h2 font-weight-bold mb-0 text-white">
                      {currentIndicator?.carbonmonoksid} PPM
                    </span>
                  </Col>
                  <Col className="col-auto">
                    <div className="icon icon-shape bg-white text-darker rounded-circle shadow">
                      <img
                        alt="..."
                        src={
                          require("assets/img/icons/carbon-monoxide.png")
                            .default
                        }
                      />
                    </div>
                  </Col>
                </Row>
                {carbonmonoksidDiff}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Col>
      <Col md="3" className="mb-3">
        <Card className="bg-gradient-default">
          <CardBody>
            <Row>
              <Col>
                <CardTitle
                  className="text-uppercase text-muted text-white mb-0"
                >
                  Karbon-dioksid (CO<sub>2</sub>)
                </CardTitle>
                <span className="h2 font-weight-bold mb-0 text-white">
                  {currentIndicator?.carbondioksid} PPM
                </span>
              </Col>
              <Col className="col-auto">
                <div className="icon icon-shape bg-white text-default rounded-circle shadow">
                  <img
                    alt="..."
                    src={
                      require("assets/img/icons/co2-1.png")
                        .default
                    }
                  />
                </div>
              </Col>
            </Row>
            {carbondioksidDiff}
          </CardBody>
        </Card>
      </Col>
      <Col md="3" className="mb-3">
        <Card className="bg-gradient-cyan">
          <CardBody>
            <Row>
              <Col>
                <CardTitle
                  className="text-uppercase text-muted text-white mb-0"
                >
                  Ozon (O<sub>3</sub>)
                </CardTitle>
                <span className="h2 font-weight-bold mb-0 text-white">
                  {currentIndicator?.ozon} PPM
                </span>
              </Col>
              <Col className="col-auto">
                <div className="icon icon-shape bg-white text-cyan rounded-circle shadow">
                  <img
                    alt="..."
                    src={
                      require("assets/img/icons/ozone.png")
                        .default
                    }
                  />
                </div>
              </Col>
            </Row>
            {ozonDiff}
          </CardBody>
        </Card>
      </Col>
      <Col md="3" className="mb-3">
        <Card className="bg-gradient-warning">
          <CardBody>
            <Row>
              <Col>
                <CardTitle
                  className="text-uppercase text-muted text-white mb-0"
                >
                  Ammonyak (NH<sub>3</sub>)
                </CardTitle>
                <span className="h2 font-weight-bold mb-0 text-white">
                  {currentIndicator?.ammonia} PPM
                </span>
              </Col>
              <Col className="col-auto">
                <div className="icon icon-shape bg-white text-warning rounded-circle shadow">
                  <img
                    alt="..."
                    src={
                      require("assets/img/icons/mouthwash.png")
                        .default
                    }
                  />
                </div>
              </Col>
            </Row>
            {ammoniaDiff}
          </CardBody>
        </Card>
      </Col>
      <Col md="3" className="mb-3">
        <Card className="bg-gradient-yellow">
          <CardBody>
            <Row>
              <Col>
                <CardTitle
                  className="text-uppercase text-muted text-white mb-0"
                >
                  Azot-dioksid (NO<sub>2</sub>)
                </CardTitle>
                <span className="h2 font-weight-bold mb-0 text-white">
                  {currentIndicator?.azotdioksid} PPM
                </span>
              </Col>
              <Col className="col-auto">
                <div className="icon icon-shape bg-white text-yellow rounded-circle shadow">
                  <img
                    alt="..."
                    src={
                      require("assets/img/icons/nitrous-oxide.png")
                        .default
                    }
                  />
                </div>
              </Col>
            </Row>
            {azotdioksidDiff}
          </CardBody>
        </Card>
      </Col>
      <Col md="3" className="mb-3">
        <Card className="bg-gradient-success">
          <CardBody>
            <Row>
              <Col>
                <CardTitle
                  className="text-uppercase text-muted text-white mb-0"
                >
                  Metan (CH<sub>4</sub>)
                </CardTitle>
                <span className="h2 font-weight-bold mb-0 text-white">
                  {currentIndicator?.metan} PPM
                </span>
              </Col>
              <Col className="col-auto">
                <div className="icon icon-shape bg-white text-success rounded-circle shadow">
                  <img
                    alt="..."
                    src={
                      require("assets/img/icons/ch4.png")
                        .default
                    }
                  />
                </div>
              </Col>
            </Row>
            {metanDiff}
          </CardBody>
        </Card>
      </Col>
      <Col md="3" className="mb-3">
        <Card className="bg-gradient-indigo">
          <CardBody>
            <Row>
              <Col>
                <CardTitle
                  className="text-uppercase text-muted text-white mb-0"
                >
                  SƏS
                </CardTitle>
                <span className="h2 font-weight-bold mb-0 text-white">
                  {currentIndicator?.sound} dB
                </span>
              </Col>
              <Col className="col-auto">
                <div className="icon icon-shape bg-white text-indigo rounded-circle shadow">
                  <i className="fas fa-volume-up" />
                </div>
              </Col>
            </Row>
            {soundDiff}
          </CardBody>
        </Card>
      </Col>
      <Col md="3" className="mb-3">
        <Card className="bg-gradient-dark">
          <CardBody>
            <Row>
              <Col>
                <CardTitle
                  className="text-uppercase text-muted text-white mb-0"
                >
                  Toz zərrəcikləri (PM 2.5)
                </CardTitle>
                <span className="h2 font-weight-bold mb-0 text-white">
                  {currentIndicator?.pm2_5} µg/m<sup>3</sup>
                </span>
              </Col>
              <Col className="col-auto">
                <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                  <img
                    alt="..."
                    src={
                      require("assets/img/icons/air-pollution.png")
                        .default
                    }
                  />
                </div>
              </Col>
            </Row>
            {pm2_5Diff}
          </CardBody>
        </Card>
      </Col>
      <Col md="3" className="mb-3">
        <Card className="bg-gradient-pink">
          <CardBody>
            <Row>
              <Col>
                <CardTitle
                  className="text-uppercase text-muted text-white mb-0"
                >
                  Toz zərrəcikləri (PM 10)
                </CardTitle>
                <span className="h2 font-weight-bold mb-0 text-white">
                  {currentIndicator?.pm10} µg/m<sup>3</sup>
                </span>
              </Col>
              <Col className="col-auto">
                <div className="icon icon-shape bg-white text-pink rounded-circle shadow">
                  <img
                    alt="..."
                    src={
                      require("assets/img/icons/air-pollution-2.png")
                        .default
                    }
                  />
                </div>
              </Col>
            </Row>
            {pm10Diff}
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}


export default Detail
