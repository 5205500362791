import { configureStore } from '@reduxjs/toolkit';

import tableReducer from './table-slice';
import deviceReducer from './device-slice';
import normReducer from './norm-slice';

const store = configureStore({
  reducer: {
    table: tableReducer,
    device: deviceReducer,
    norm: normReducer,
  }
});

export default store;
